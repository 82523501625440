<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12" sm="6" lg="4">
            <v-row>
              <v-col cols="6">
                <v-card flat outlined>
                  <v-responsive :aspect-ratio="1 / 1">
                    <v-list-item
                      link
                      dark
                      :to="{ name: 'PageNewVehicleRunning' }"
                      class="d-flex align-center justify-center pa-3 lime"
                    >
                      <v-sheet
                        color="transparent"
                        class="d-flex flex-column align-center text-center"
                      >
                        <v-icon large> mdi-counter </v-icon>
                        New Vehicle Running Log
                      </v-sheet>
                    </v-list-item>
                  </v-responsive>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card flat outlined>
                  <v-responsive :aspect-ratio="1 / 1">
                    <v-list-item
                      link
                      dark
                      :to="{
                        name: 'PageMyVehicleRepairList',
                        query: { new: 'true' },
                      }"
                      class="d-flex align-center justify-center pa-3 orange"
                    >
                      <v-sheet
                        color="transparent"
                        class="d-flex flex-column align-center text-center"
                      >
                        <v-icon large> mdi-wrench-outline </v-icon>
                        New Vehicle Repair Log
                      </v-sheet>
                    </v-list-item>
                  </v-responsive>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card flat outlined>
                  <v-responsive :aspect-ratio="1 / 1">
                    <v-list-item
                      link
                      dark
                      :to="{
                        name: 'PageMyVehicleFuelList',
                        query: { new: 'true' },
                      }"
                      class="d-flex align-center justify-center pa-3 teal"
                    >
                      <v-sheet
                        color="transparent"
                        class="d-flex flex-column align-center text-center"
                      >
                        <v-icon large> mdi-gas-station-outline </v-icon>
                        New Vehicle Fuel Log
                      </v-sheet>
                    </v-list-item>
                  </v-responsive>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card flat outlined>
                  <v-responsive :aspect-ratio="1 / 1">
                    <v-list-item
                      link
                      :to="{ name: 'PageNewLeaveApplication' }"
                      dark
                      class="d-flex align-center justify-center pa-3 red"
                    >
                      <v-sheet
                        color="transparent"
                        class="d-flex flex-column align-center text-center"
                      >
                        <v-icon large> mdi-calendar-outline </v-icon>
                        New Leave Application
                      </v-sheet>
                    </v-list-item>
                  </v-responsive>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <v-row>
              <v-col cols="12">
                <v-card flat outlined>
                  <v-list-item dark class="text-h6 purple">
                    Your Projects
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-sheet
                    color="transparent"
                    v-for="project in myProjects"
                    :key="project.uuid"
                  >
                    <v-divider></v-divider>
                    <v-list-item link>
                      {{ project.code }} - {{ project.name }}
                    </v-list-item>
                  </v-sheet>
                  <v-sheet color="transparent" v-if="myProjects.length == 0">
                    <v-divider></v-divider>
                    <v-list-item> No Projects.</v-list-item>
                  </v-sheet>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card flat outlined>
                  <v-list-item dark class="text-h6 green">
                    Your Vehicles
                  </v-list-item>
                  <v-sheet
                    color="transparent"
                    v-for="vehicle in myVehicles"
                    :key="vehicle.uuid"
                  >
                    <v-divider></v-divider>
                    <v-list-item link>
                      {{ vehicle.vehicle_no }}
                      <v-chip small class="ms-1">
                        {{ vehicle.class }}
                      </v-chip>
                      <v-chip small class="ms-1">
                        {{ vehicle.category }}
                      </v-chip>
                    </v-list-item>
                  </v-sheet>
                  <v-sheet color="transparent" v-if="myVehicles.length == 0">
                    <v-divider></v-divider>
                    <v-list-item> No Vehicles.</v-list-item>
                  </v-sheet>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" lg="5" class="d-none d-lg-block">
            <v-card
              class="d-flex flex-column align-center py-12 blue"
              flat
              light
            >
              <!-- <div class="secondary--text mb-5">
                <v-icon color="secondary" small>mdi-tools</v-icon>
                app under construction
              </div> -->

              <div
                color="transparent"
                class="text-h1 d-flex align-center"
                style="font-weight: 900; color: #07617b"
              >
                

             
                IMS
                <v-img max-width="80" alt="IMSO" src="/images/Icon.png" />
              </div>

              Information Management System of OPEnE

              <v-date-picker
                no-title
                class="mt-5"
                color="primary"
              ></v-date-picker>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import {
  fetchAndStoreMyVehicles,
  fetchAndStoreMyProjects,
} from "@/services/myDataService";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    breadcrumbsItems: [
      {
        text: "Dashboard",
        disabled: true,
        href: "",
      },
    ],
    myProjects: [],
    myVehicles: [],
    loading: false,
  }),
  async created() {
    this.loading = true;
    this.myProjects = await fetchAndStoreMyProjects();
    this.myVehicles = await fetchAndStoreMyVehicles();
    this.loading = false;
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>

<style scoped>
.lime {
  height: 100%;
  width: 100%;
  /* background-image: linear-gradient(-60deg, #16a085 0%, #f4d03f 100%); */
  background-image: linear-gradient(
    -225deg,
    #f4d03f 0%,
    #16a074 78%,
    #16a074 100%
  );
  transition: 0.5s;
  background-size: 200% auto;
}
.lime:hover {
  background-position: right center;
}

.orange {
  height: 100%;
  width: 100%;
  /* background-image: linear-gradient(-60deg, #ff5858 0%, #f09819 100%); */
  background-image: linear-gradient(
    -225deg,
    #f0a119 0%,
    #f53f27 88%,
    #f53f27 100%
  );
  transition: 0.5s;
  background-size: 200% auto;
}
.orange:hover {
  background-position: right center;
}

.teal {
  height: 100%;
  width: 100%;
  /* background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%); */
  background-image: linear-gradient(-225deg,
    #0093e9 0%,
    #0093e9 18%,
    #80d0c7 100%);
  transition: 0.5s;
  background-size: 200% auto;
}
.teal:hover {
  background-position: right center;
}

.red {
  height: 100%;
  width: 100%;
  /* background-image: linear-gradient(-60deg, #ff0844 0%, #ffb199 100%); */
  background-image: linear-gradient(-225deg,
    #ffb199 0%,
    #ffb199 18%,
    #ff0844 100%);
  transition: 0.5s;
  background-size: 200% auto;
}
.red:hover {
  background-position: right center;
}

.purple {
  background-image: linear-gradient(
    -225deg,
    #5271c4 0%,
    #b19fff 48%,
    #eca1fe 100%
  );
  transition: 0.5s;
  background-size: 200% auto;
}
.purple:hover {
  background-position: right center;
}

.green {
  background-image: linear-gradient(60deg, #64b3f4 0%, #c2e59c 100%);
  transition: 0.5s;
  background-size: 200% auto;
}
.green:hover {
  background-position: right center;
}

.blue {
  background-image: linear-gradient(to top, #89afda 0%, #e7f0fd 100%);
}
</style>